.driver-list {
    margin: 0;
}
.flexPagination {
    .table-result {
        overflow: auto;
        height: 40vh;
        .MuiPaper-rounded {
            padding: 0 !important;
        }
        .MuiTableContainer-root {
            height: 40vh;
        }
    }
    .boxRangeAlerts {
        display: flex;
        justify-content: space-between;
        .menu-range {
            margin: 0 10px;
        }
    }
    .MuiPagination-ul {
        justify-content: flex-end;
    }
}
