.filtro-container {
	display: flex;

	.MuiIconButton-root {
		flex: 0 0 auto;
		color: rgba(0, 0, 0, 0.54);
		padding: 0;
		overflow: visible;
		font-size: 1.0714285714285714rem;
		text-align: center;
		transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		border-radius: 50%;
	}

	.autoComplete .MuiInputBase-root {
		height: 30px;
		padding: 1px;
	}

	.autoComplete .MuiChip-root {
		height: 20px;
	}
	.autoComplete .MuiFormControl-marginDense {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

.filter-period {
	.MuiSelect-icon {
		margin-top: 0.5rem;
		margin-right: 0.5rem;
	}

	.MuiAutocomplete-endAdornment {
		top: calc(50% - 7px);
	}
}
