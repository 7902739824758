$login_badge_bottom: 10px;
$login_badge_left: 10px;

$login_padding_lr: 8px;
$login_padding_tb: 2px;

.app-info-badge {
	position: absolute;
	bottom: $login_badge_bottom;
	left: $login_badge_left;
	font-size: 0.7rem !important;
	background: rgba(0, 0, 0, 0.4);
	color: white;
	padding: $login_padding_tb $login_padding_lr;

	.app-info-badge-icon {
		height: 0.6rem;
	}
}

.app-info-badge-tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.app-info-badge-tooltip .tooltiptext {
	visibility: hidden;
	background-color: #0000003b;
	color: #fff;
	padding: 5px 8px;
	border-radius: 6px;
	bottom: 23px;
	left: 10px;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
	span {
		white-space: nowrap;
		display: block;
	}
}

/* Show the tooltip text when you mouse over the tooltip container */
.app-info-badge-tooltip:hover .tooltiptext {
	visibility: visible;
}
