.tableGridData {
    th {
        padding: 0px 0px 15px;
        text-align: center;
    }

    tr td {
        padding: 5px 0px 3.5px;
        text-align: center;
    }

    .countItem {
        font-weight: bold;
        font-size: 26px;
        color: #afc3d2;
    }

    .gridDataFont {
        display: flex;
        font-weight: bold;
        color: #3d5564;
        font-size: 20px;
    }

    .rowFont {
        font-size: 14px;
    }

    .headerGridData {
        margin: 10px 5px;
    }

    .bodyGridData {
        margin: 10px 5px;
    }
}

.loadingGridData {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
}


.driver-box .tableGridData tr td{
    text-align: left;
}