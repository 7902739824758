.container-alert-card {
	.alert-card.card {
		
		contain: content;
		display: inline-block;
		width: 300px;
		height: 200px;
		border-radius: 2px;
		color: #3d5564;
		position: relative;
		padding: 0.2rem;

		.main, .children {
			display: flex;
			height: 100%;
			width: 100%;
			justify-items: center;
			align-items: center;
			box-sizing: border-box;
			position: relative;
		}

		.MuiTypography-root,
		MuiTypography-body1 {
			font-size: 14px;
		}

		&.include-card {
			// padding: 1rem 0.5rem;
			// margin-left: 0;


			.include-button {
				svg {
					width: 55px;
					height: 55px;
				}
			}

			.MuiTypography-root,
			MuiTypography-body1 {
				font-size: 18px;
				font-weight: bold;
				span {
					text-transform: uppercase;
				}
			}
		}

		&.help-card {
			width: 250px;
			height: 200px;
			.main {
				flex-direction: column;
				padding: 1rem 0;
			}

			.header {
				display: flex;
				align-items: center;
				justify-items: stretch;
				justify-content: stretch;

				// max-width: 90%;
				box-sizing: border-box;
				flex: 1.5;
				margin: 0 1rem;
				border-bottom: 1px solid rgba($color: #afc3d2, $alpha: 0.6);
				padding-bottom: 0.5rem;

				img {
					margin-right: 0.4rem;
					width: 41px;
					
				}

				.MuiTypography-root,
				MuiTypography-body1 {
					font-size: 16px;

					font-weight: bold;
				}
			}

			.text {
				padding-top: 0.5rem;
				flex: 3;
				margin: 0 1rem;
			}

			.footer {
				flex: 0.5;
				display: flex;
				width: 100%;
				padding-right: 0.3rem;
				justify-content: flex-end;
				position: absolute;
				bottom: 0;

				.help-button {
					svg {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
}
