.edit-alert {
    padding: 1rem;
    position: relative;

    > div {
        >.MuiGrid-item:first-child {
            padding-left: 16px;
        }
    }

    .top-button {
        .MuiButton-label {
            text-transform: none !important;
            font-weight: bold;
            font-size: 14px;
        }
    }
}
