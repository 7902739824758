input[type="file"] {
    display: none;
}

.label-input-file {
    border-radius: 2px;
    color: #afc3d2;
    cursor: pointer;
    margin: 0px;
    border: none;
    display: flex;
    padding-left: 2px;
    align-items: center;
    justify-content: space-between;
    background-color: #afc3d247;
}

.label-input-file p {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 0.5rem;
}

.label-input-file {
    #file-input-text {
		font-size: 14px;
		padding-bottom: 2px;
    }

    #file-input-pick-button {
        font-size: 16px;
    }
}

.btn-file {
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 5px;
    width: 120px;
    border-radius: 3px;
}

.btn-file p {
    color: #fff;
    padding: 0;
}

.title {
    font-weight: bold !important;
}

.back-icon {
    margin-left: 0.5rem;
}
