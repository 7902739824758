.importCarAlert {
    padding: 1rem 1.5rem;

    .fontCheckBox {
        display: flex;
        align-items: center;
        color: #3d5564;
        font-size: 14px;
    }

    .grid-item {
        margin: 0px 0px 10px 0px;
    }

    .grid-buttons {
        justify-content: flex-end;

        .import-cancel-button {
            background: none !important;

            p {
                color: #afc3d2;
            }
        }

        .btnConfirmAlert {
            display: flex;
            align-items: center;

            p {
                display: flex;
                align-items: center;
                margin: 0 0px 0 5px;
            }
        }
    }
}