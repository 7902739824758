.expansionGrid {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
}

.card {
    margin: 1rem;
    position: relative;
    width: auto;
    height: auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
    flex: 1 1 auto;
    order: 1;
    transition: all 150ms ease-out;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .boxTitle {
        font-family: "Trebuchet MS", sans-serif;
        width: 100%;
        height: auto;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(175, 195, 210, 0.56);
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.53px;
        color: rgba(61, 85, 100, 1);
        margin-bottom: 10px;
        position: relative;
        vertical-align: top;

        .titleButtons {
            display: flex;
            justify-content: flex-end;
        }
    }


    .card-title-divider {
        display: none;
    }
    .topButtons {
        position: absolute;
        right: 5px;
        top: -5px;
        z-index: 50;
    }

    .children {
        flex: 1;
        position: relative;
        height: 90%;
    }

    &.notPadded {
        padding: 0;

        .boxTitle {
            padding-top: 20px;
            margin: 0 20px;
            width: auto;
        }
    }
}

.expansionCardAlignIcon {
    padding: 5px;

    svg {
        height: 25px;
        width: auto;
    }
}

div.card.expandedCard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    transition: all 150ms ease-out;
    z-index: 1100;
    box-sizing: border-box;
}

.horizontal,
.horizontalInverse {
    .card {
        height: 80vh;
    }
}

.vertical,
.verticalInverse {
    .card {
        height: 75vh;
    }
}

.horizontal {
    flex-direction: row;
}

.vertical {
    flex-direction: column-reverse;
    .card {
        min-height: 450px;
    }
}

.horizontalInverse {
    flex-direction: row-reverse;
}

.verticalInverse {
    flex-direction: column;
}