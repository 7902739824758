.classMainTabDash {
    .itemsDash {
        width: 100%;
    }
}

.evolutionDash {
    .loadingGridData {
        width: 100%;
        height: 100%;
    }
}

#TopTabDashboardVehicle {
    .expansionGrid.vertical {
        height: 100%;

        .dashboardVehicleSecondaryTabMap {
            height: 58vh;
        }
        .expandedCard.dashboardVehicleSecondaryTabMap {
            height: 100%;
        }
    }
}

.dashboardVehicleSecondaryTabMap {
    .children {
        height: calc(100% - 1.7rem);
    }
    .mapScreen {
        height: 100%;
        .map-card {
            height: 100% !important;
        }
    }
}
