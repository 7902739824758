.custom-table.MuiTableContainer-root {
    &.fixed-height {
        max-height: 50vh;
        overflow-y: auto;
    }

    .MuiTableCell-alignCenter {
        border-right: 1px solid #ccce;
        white-space: nowrap;
    }

    .MuiTableCell-alignCenter:last-child {
        border: none;
    }

    .MuiTableCell-body {
        border-right: 1px solid #ccce;
        text-align: center !important;
        padding: 2px 5px 0px 5px !important;
        white-space: nowrap;
    }

    .MuiTableCell-body:last-child {
        border: none;
    }

    .MuiTableCell-root {
        border-bottom: none;
    }

    .pagination {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        margin-top: 15px;
    }

    .arrowCheck {
        background: white;
        display: flex;
        position: relative;
        font-weight: bold;
        font-size: 20px;
        height: 25px;
    }
}

.table-result {
    th {
        padding: 1px 5px;

        p,
        MuiTypography-root {
            font-size: 14px;
            font-weight: bold;
            text-align: start;
        }


    }

    td {
        div {
            display: flex;
        }

        span {
            font-size: 14px;
            text-transform: capitalize;
        }

        p,
        MuiTypography-root {
            font-size: 14px;
            text-align: start;
        }

    }

    tr.MuiTableRow-root {
        height: 25px;
    }
    
    .MuiCheckbox-root {
        span svg {
            height: 24px;
            width: 24px;
        }
    }
}

.expandedCard {
    .custom-table.MuiTableContainer-root.fixed-height {
        max-height: unset;
    }
}
