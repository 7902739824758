.ceabs-layout .ceabs-register-form.ceabs-register-point-interest {
	.button-form {
		padding-top: 1.5rem;
	}
}

#point-interest-autocomplete {
	p.MuiFormHelperText-root {
		top: 4.6rem;
	}
}