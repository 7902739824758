.viewItemdata {
    display: flex;
    box-shadow: 0.2px 0.2px #afc3d2;
    margin: 0 15px 0 0;
    flex: 1;

    p.MuiTypography-root {
        font-size: 14px;
        white-space: nowrap;
        margin: 0;
    }

    .column {
        margin: 0;
        padding: 2px 30px;
        background: #afc3d354;
        display: flex;
        align-items: center;
    }

    .data {
        margin: 0;
        padding: 3px 30px 3px 15px;
        display: flex;
        align-items: center;
    }
}
