.cardsAlertsListsReport {

    position: relative;
    width: 100%;

    .card.card-alerts-list {
        width: 100%;
        .table-result {
            display: block;
			overflow: auto;
			width: 84vw;

            .MuiTableContainer-root {
                overflow: initial !important;
            }
        }
    }

    .card.card-alerts-list.expandedCard  {
        .table-result {
            width: 100%;
            max-height: 75vh;
        }
    }

    .MuiListItem-root{
    	padding-top:0;
    	padding-bottom: 0;
    }

    .pagination{
    	display: flex;
    	justify-content:flex-end;
    	margin-right: 45px;
    }

    .card-alerts-list {
    	.itemTitle {
    		width: auto !important;
    	}

    	.titleBtn {
    		display: flex;
    		width: 80%;
    		align-items: center;
            justify-content: flex-start !important;

    		.boxSearchComp {
    			flex: none;
    			width: 250px;
    			margin: 0 8px;
    		}
    	}
    }

    .card-alerts-list-tabs {
    	padding: 0;
    }

    th li.MuiListItem-gutters span {
    	text-transform: none;
    	white-space: nowrap;
    }
}
