.alertDetails {
	.doneAlert {
		width: 30px;
		min-width: 30px;
		height: 40px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}


	.main-accordion {
		padding-bottom: 0px;
	}

	.boxTextArea {
		width: calc(100% - 44px);
		margin: 0;
		& > div {
			height: 100%;
			&::after {
				border-bottom: none;
			}
		}

		textarea: {
			font-size: 14px;
		}

		.MuiFormHelperText-root.Mui-error {
			font-size: 12px;
		}
	}

	.legend {
		color: #afc3d2;
	}

	.gps {
		position: relative;
		display: inline-block;
		img {
			position: relative;
			top: 0;
			width: 24px;
		}
	}

	.ignition {
		display: inline-block;
		width: 15px;
		height: 15px;
		border-radius: 50%;
	}

	.ignitionOn {
		background: #71c154;
		border: 2px solid rgba(223, 255, 212, 0.48);
	}

	.ignitionOff {
		background: #c15454;
		border: 2px solid rgba(251, 203, 203, 0.48);
	}
}
