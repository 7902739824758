.ceabs-layout .MuiList-root .MuiButtonBase-root.dropdown-icon {
	flex-direction: unset;
	display: flex;
    align-items: center;
    justify-content: right;
    padding: 0.8rem 1rem;
    padding-left: 1rem;
    
    .icon {
        width: 30px;
        margin-right: 5px;
        svg {
            font-size: 18px;
            overflow: visible;
        }
    }

	.text {
        font-size: 12px;
		line-height: 17px;
	}
}
