.boxFormEditUser {
    > .MuiGrid-item:first-child {
        padding-left: 16px;
    }

    .top-button {
        .MuiButton-label {
            text-transform: none !important;
            font-weight: bold;
            font-size: 14px;
        }
    }

    #user-view-vehicles {
        .register-switch {
            align-items: flex-start;
            padding: 0;
            margin-top: -10px;
            label {
                margin-left: 0;
            }
        }
    }
    
    div.itemViewVehicles.MuiGrid-item {
        padding-left: 16px;
    }
}
