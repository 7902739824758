.alert-form.ceabs-register-form {
    margin-left: -8px;
    .field-condition {
        padding-bottom: 0.8rem;

        .error-condition.MuiFormHelperText-root {
            position: unset;
            padding-left: 5px;
        }
    }

    .MuiButtonBase-root.add-condition-button {
        padding: 0;
        .MuiButton-label {
            justify-content: start;
            padding-left: 0.5rem;
            font-weight: bold;
            font-size: 14px;
            color: #3d5564;
        }
        margin-top: 8px;
        height: 3rem;
    }

    .MuiButtonBase-root.cancel-adorment {
        position: absolute;
        right: 5px;
    }

    .button-form.MuiBox-root {
        justify-content: flex-start;
        padding-top: calc(24px + 0.7rem);

        button {
            margin-bottom: 1px;
        }
    }

    .alert-time-start {
        .MuiInputBase-root {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .hour-conditions {
        .MuiFormHelperText-root {
            margin: 0;
            width: 200%;
        }

        .MuiInputBase-root input {
            padding-right: 0.3rem;
        }
    }

    .alert-time-end {
        .MuiInputBase-root {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.alert-form.alert-form-compact {
    margin-left: 0;
    .alert-form-grid {
        justify-content: flex-end;
    }
}
