.tab-login-comeback {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	.MuiButtonBase-root.MuiButton-root .MuiButton-label {
		text-transform: capitalize;
		font-size: 18px;
		font-family: "Trebuchet MS";
		color: #afc3d2;
	}
}

#tabs-login-container {
	width: 100%;
	box-sizing: border-box;

	button {
		padding-left: 0;
	}
}