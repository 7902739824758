.mapFilter {
	padding: 1rem;
	position: relative;

	max-width: 800px;

	.formControl {
		margin: 10px;
		width: 200px;

		.selectNoFill {
			color: #000;
			background: #eef2f5 !important;
			// borderColor: #AFC3D2;
			padding: 0;
			width: 200px;
			height: 38px;

			& > div {
				padding: 10px;
			}

			fieldset {
				border-color: #afc3d2;
			}

			.MuiOutlinedInput-root:hover fieldset {
				border-color: #3d5564;
			}
		}

		.selectFill {
			color: #fff;
			background: #afc3d2;
			// borderColor: #AFC3D2;
			padding: 0;
			width: 200px;
			height: 38px;

			svg {
				color: #fff;
			}

			label {
				color: #fff;
			}

			& > div {
				padding: 10px;
			}

			fieldset {
				border-color: #afc3d2;
			}

			.MuiOutlinedInput-root:hover fieldset {
				border-color: #3d5564;
			}
		}

		label {
			transform: translate(14px, 10px) scale(1);
			text-transform: none;
		}

		label.Mui-focused,
		label.MuiFormLabel-filled {
			transform: translate(14px, -8px) scale(0.75);
		}
	}

	.formControl.fill {
		label {
			color: #fff;
		}
	}
}

.ceabs-layout .MuiButton-root.button-popover .MuiButton-label {
	text-transform: none;
}

.toolbar-checkbox {
	white-space: nowrap;
	.MuiIconButton-label .MuiSvgIcon-root {
		height: 24px;
		width: 24px;
	}

	span.MuiButtonBase-root {
		padding-bottom: 4px;
	}

	span.MuiTypography-root {
		font-size: 14px;
		padding-top: 6px;
	}
}