.boxAccordion {
	border: 1px solid #cce;
	border-radius: 4px;

	.itemTitle {
		display: flex;
		padding: 5px 0px 5px 5px;
		border-bottom: 1px solid #cce;
		height: 3vh;
		position: sticky;
		z-index: 999;
		background-color: #fff;
		border-radius: 4px;
		top: 0;

		.MuiDivider-vertical {
			background: #afc3d2;
			right: -18px;
			width: 1px;
			top: 0;
			height: auto;
		}

		.name {
			flex: 7 1;
			display: flex;
			align-items: center;
		}

		.status {
			flex: 1 1;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 7px;
		}

		p {
			color: #afc3d2;
		}
	}

	.boxListItemAccordion {
		padding: 0;
	}

	.contentBoxAccordion {
		display: flex;
		border-bottom: 1px solid #cce;
		position: relative;

		.MuiDivider-vertical {
			height: auto;
			background-color: #afc3d2;
		}

		.main-accordion {
			flex: 7;
			padding: 0;
			min-width: 0;

			.titleStyles {
				font-weight: bold;
			}

			.MuiCollapse-container {
				border: none;

				.MuiAccordionDetails-root {
					> div {
						min-width: 0;
					}
				}
			}

			.MuiAccordionSummary-root {
				border: none;
				border-radius: 3px 0px 0px 3px;
			}
		}

		.itemAccordionBox {
			padding: 2px 0px 2px 5px;
			width: 117.5%;
			background-color: #fff;
			position: relative;
			display: flex;
			min-width: 0;

			p {
				font-size: 14px;
				font-weight: bold;
				color: #3d5564;
				flex: 7 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				min-width: 0;
				margin-left: 16px;
			}

			.itemAccordionBoxStatus {
				flex: 1 1;
				min-width: 0;
				padding: 0 7px;
			}
		}

		.itemBoxcolor {
			background-color: #f5f7f9; //#afc3d2
		}

		.itemStatus {
			flex: 1;
			max-height: 30px;
			padding: 7px;
			color: #3d5564;
			display: flex;
			align-items: center;
			border-radius: 0px 3px 3px 0px;
		}

		.active {
			color: #fff;
		}

		.arrow {
			position: absolute;
			top: 0px;
			right: -15px;
		}
	}
}
