.report-field {
    .MuiFormControl-root {
        margin: 0;

        .MuiInputBase-root {
            background-color: #eef2f5;
            font-size: 13px;
            min-height: 40px;

            ::placeholder {
                color: #afc3d2;
                opacity: 1;
            }

            &:not(.Mui-error) fieldset {
                border: 1px solid rgba($color: #afc3d2, $alpha: 0.7);
            }

            .MuiAutocomplete-endAdornment {
                background-color: #eef2f5;

                transition: 0.4s;

                padding-top: 0.2rem;

                right: 0;
                padding-right: 9px;

                svg {
                    color: #3d5564;
                    overflow: visible;
                }
            }

            &.MuiOutlinedInput-notchedOutline {
                fieldset {
                    border: 1px solid #afc3d2;
                }
            }
        }
    }

    .MuiSelect-icon {
        top: unset;
    }

    .MuiFormHelperText-root {
        position: absolute;
        bottom: -1.5rem;
        font-size: 12px;
        margin: 0;
        margin-left: 5px;

    }
}

.period-date-picker {
    cursor: pointer;
    // background-color: #eef2f5;
    // border: 1px solid #afc3d2;
    position: relative;

    .period-start-date {
        width: calc(47% - 1px);

        .MuiInputBase-root.MuiOutlinedInput-root {
            cursor: pointer;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;

            .MuiInputBase-input {
                padding-right: 0.5rem;
                text-align: center;
            }

            fieldset {
                border-right: none;
            }
        }

    }

    .period-divider {
        // border: 1px solid red;
        height: 20px;
        width: 1px;
        

        display: block;
        background-color: rgba($color: #AFC3D2, $alpha: 0.9);
        box-sizing: border-box;
        position: absolute;
        top: 7px;
        left: 46.5%;
        z-index: 3;

    }

    .period-end-date {
        width: 53%;

        .MuiInputBase-root.MuiOutlinedInput-root {
            cursor: pointer;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            padding-right: 0;

            fieldset {
                border-left: none;
            }

            .MuiInputAdornment-root {
                max-height: unset;
            }

            .MuiInputBase-input {
                padding-left: 0.5rem;
                text-align: center;
            }

            .MuiButtonBase-root {
                padding: 0;
                width: 100%;
                height: 100%;

                .div-icon-period {
                    border: 1px solid #afc3d2;
                    border-radius: 3px;
                    padding: 0;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 36px;
                    height: 36px;

                    img {
                        width: 20px;
                    }
                }
            }
        }
    }
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 0 !important;
}

.period-date-picker {
    .MuiFormHelperText-root {
        white-space: nowrap;
    }
}
