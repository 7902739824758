.indicator {
	background-color: rgba(110, 200, 175, 0.15);
}

.title {
	text-align: left;
	font: normal normal bold 30px Trebuchet MS;
	letter-spacing: 0px;
	color: #3d5564;
	opacity: 1;
}

.km {
	font: normal normal bold 25px Trebuchet MS;
	letter-spacing: -1.5px;
	color: #3d5564;
}

.media-veiculo {
	text-align: left;
	font-size: 15px;
	color: #3d5564;
}

.media-veiculo-km {
	text-align: left;
	font: normal normal bold 15px Trebuchet MS;
	letter-spacing: 0px;
	color: #3d5564;
}
