.contentSide {
  padding: 0;
  background: none;
  box-shadow: none;
}

.contentSide .card {
  margin: 0;
}

.alertMap {
  display: flex;
}

.displayAlerts {
  flex: 1;

  .boxTitle {
    margin-top: -20px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 15px;
    width: auto;
  }

  .children {
    height:40vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -5px;
  }
}

.alert-card .card {
	.boxTitle {
		padding: 0 2rem;
		border: 0;
		display: flex;
		align-items: center;
		background-color: rgba($color: #6ec8af, $alpha: 0.15);
	}
}

.alert-map.card {
  height: 60vh;
  margin: 15px;
  flex: 1.5;
  box-sizing: border-box;
  padding-top: 0;

  .boxTitle {
    margin: 0;
    padding: 15px;
    box-sizing: border-box;
  }

  .children {
    height: 88%;
    box-sizing: border-box;
  }
}

.alert-container {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}


.loadingAlert {
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 5000;
  margin: 15px;
  box-sizing: border-box;
  padding-top: 150px;
}
