.box-alert-card {
    border: 1px solid #afc3d2;
    border-radius: 3px;
    color: #3d5564;
    margin: 0.5rem;

    &.full-height {
        height: calc(100% - 1rem);
        box-sizing: border-box;
    }

    .title-box {
        padding: 0.5rem;
        padding-left: 0.7rem;
        border-bottom: 1px solid #afc3d2;
        border-radius: 5px 5px 0 0;
        background: #eef2f5;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 40px;
        box-sizing: border-box;

        & > span {
            display: flex;
            align-items: center;
        }

        .title-icon {
            margin-right: 0.5rem;
        }
    }

    .content-box {
        height: calc(100% - 40px);
        box-sizing: border-box;
        border-radius: 0 0 5px 5px;

        .box-card-alert-item {

            &.with-hover:hover {
                background-color: rgba($color: #afc3d2, $alpha: 0.3);
            }

            min-height: 2rem;
            border-bottom: 1px solid #afc3d2;
            box-sizing: border-box;
            display: flex;
            padding: 0.5rem;
            padding-left: 0.7rem;

            &:last-child {
                border-bottom: none;
            }

            .item-legend-box {
                color: #afc3d2;
                font-size: 14px;
                width: 85px;
            }

            .item-content-box {
                flex: 3;
                font-size: 14px;
                color: #3d5564;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 10px;
            }
        }
    }
}
