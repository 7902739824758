.MuiTabs-root {
	.MuiTab-root {
		min-width: 96px;
		padding: 10px 4px;
	}

	.MuiTabs-indicator {
		height: 5px;
	}

	.Mui-selected .MuiTab-wrapper .MuiTypography-body1 {
		color: #3d5564;
	}

	.MuiTab-wrapper {
		flex-direction: row;
		justify-content: start;

		.MuiTypography-body1 {
			font-size: 20px;
			font-weight: bold;
		}
	}
}

// css internal

.tabMain {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	position: relative;
}

.tabPanel {
	overflow-y: auto;
	overflow-x: hidden;
	// border: 1px solid indigo;
	// height: 40vh;
	box-sizing: border-box;
	// height: 100%;
	height: auto;
	height: calc(100% - 4rem);
}

.selectItem {
	padding-right: 10px;
}

.tabTittle {
	font-size: 13;
}

.tabs {
	width: 100%;
	border-bottom: 0.1px solid #afc3d2;
	display: flex;
	height: 4rem;
	box-sizing: border-box;
	z-index: 2;

	.MuiTabs-root {
		height: 100%;
	}
}

.tabActiveTableMap {
	position: absolute;
	top: -5px;
	left: 25rem;
	width: 30%;
	border: none;
}

@media only screen and (max-width: 1900px) {
	.tabActiveTableMap {
		left: 20rem;
	}
}

.tabPanel {
	overflow-y: "auto";
	overflow-x: "hidden";
	padding: "0px 10px 0px 0px";
}

.selectItem {
	padding-right: "10px";
}
.tabTittle {
	font-size: 13;
}

.pagination-tabmap {
	display: flex;
	align-items: center;
	justify-content: center;
}

#tabAddressPanel {
	.tabActiveTableMap {
		margin-top: 0;
		margin-left: 55px;
	}
}

/* width */
::-webkit-scrollbar {
	height: 6px; /* height of horizontal scrollbar ← You're missing this */
	width: 6px; /* width of vertical scrollbar */
	border: 1px solid #d5d5d5;
	z-index: 5;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #afc3d2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #afc3d2;
}

.map-tab {
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;
	position: relative;

	&.floating-header {
		.map-tab-header {
			position: absolute;
			width: calc(100% - 6px);
			top: 0;
			box-sizing: border-box;
			z-index: 9;

			margin: 0;
			padding: 10px 5px 0px 0;
			background-color: #fff;
			border-bottom: 5px solid #fff;
		}
	}

	.map-tab-main {
		padding-right: 5px;
		flex: 1;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.map-tab-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
