.alert-bond{
    .MuiGrid-item{
        width: 50%;
    }

}
.link-back{
    font-weight: 600;
    color: #333;
    cursor: pointer;
}