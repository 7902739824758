.report-field-label.MuiTypography-subtitle1 {
	color: #3d5564;
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 0.25rem;
	letter-spacing: 0;
}

.MuiButtonBase-root.report-save-button {
	height: 35px;
	text-transform: none !important;

	.MuiButton-label {
		font-weight: bold;
		font-size: 14px;
		line-height: 15px;

		img {
			width: 15px;
		}
	}
}

.MuiButtonBase-root.report-cancel-button {
	height: 35px;
	text-transform: none !important;
	background-color: #afc3d2;

	.MuiButton-label {
		font-weight: bold;
		font-size: 14px;
		line-height: 15px;
		color: #fff;

		img {
			width: 15px;
		}
	}
}

.pagination-table-period {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 0.5rem;
}

.card.card-report {
	border-radius: 2px;
	background-color: #fff;
	padding: 1.5rem;
}

.report-panel {
	display: flex;
}

.reportAlertsMap {
	.children {
		height: calc(100% - 2.15rem);
	}

	.mapScreen {
		height: 100%;
		.map-card {
			height: 100% !important;
		}
	}
}

.vertical,
.verticalInverse {
	.reportAlertsMap,
	.reportDistanceMap,
	.travelsObjectMap {
		height: 58vh;
	}
	.reportAlertsMap.expandedCard,
	.reportDistanceMap.expandedCard,
	.travelsObjectMap.expandedCard {
		height: 100%;
	}
}

.reportDistanceMap {
	.children {
		height: calc(100% - 1.65rem);
	}

	.mapScreen {
		height: 100%;
		.map-card {
			height: 100% !important;
		}
	}
}

.travelsObjectMap {
	.children {
		height: calc(100% - 2.1rem);
	}

	.card-report-map {
		height: 100%;
		.map-card {
			height: 100% !important;
		}
	}
}
