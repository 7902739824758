.max-velocity-modal.card {
    height: 90%;
    width: 85%;
    box-sizing: border-box;
    margin: 1rem auto;

    .topButtons {
        top: 0;
    }

    .max-velocity-modal-map {
        height: 70vh;
    }
}
