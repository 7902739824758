.mainFleet {
    width: 100%;
    min-width: 280px;
    background-color: white;
    padding: 0 !important;
    margin-bottom: 10px !important;
}

.columnsCardFleet {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.titleCard {
    flex: 1;
    padding: 5px 9.3px 5px 8.5px;
    margin-right: 5px;
}

.cardItemFleet {
    display: flex;
    padding: 0px 0px 0px 2px;
    height: auto;
}

.cardItemColumnFleet {
    flex: 1;
    height: 100%;
    align-items: center;
}

.cardItemValueFleet {
    flex: 4;
    padding-left: 5px;
    flex-direction: row;
}

.buttonVehicle {
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: contents;
}

/* VEHICLE */

.cardVehicleItem {
    display: flex;
    padding: 0px 0px 0px 2px;
    height: auto;
}

.cardVehicleItemColumn {
    flex: 3;
    flex-direction: row-reverse;
    height: 100%;
    align-items: center;
    background: rgba($color: #afc3d254, $alpha: 0.13);
}

.cardVehicleItemValue {
    flex: 6;
    padding-left: 5px;
    flex-direction: row;
    background: white;
}

.table-map-fleet.table-result {
	
    table {
        tr.MuiTableRow-root, th.MuiTableCell-root {

			height: 35px;
        }

        tbody {
            tr.MuiTableRow-root {
                background-color: #fff;
				td.MuiTableCell-body {
					border-bottom: 1px solid #d0dbe4;
				}
			}
        }
    }
}
