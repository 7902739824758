
.chart {
    flex: 1;
    display: flex;
    flex-direction: row;
    height:300px;
    .x-axis {
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        text-align: center;
        background: rgba(175, 195, 210, 0.2);
        .MuiTypography-root {
            color: #3d5564;
            height: auto;
            margin: auto;
            padding: 1.5rem 0.5rem;
            font-size: 20px;
        }
    }

    .echart {
        flex: 1;
    }
}