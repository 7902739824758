.evoltuion-fleet-table.MuiTable-root {
    thead th.MuiTableCell-root {
        font-size: 20px;
        font-weight: bold;
    }

    tbody td.MuiTableCell-root {
        font-size: 18px;
    }
}
