.boxButton {
    margin-bottom: 5px;
}

.ceabs-register-form.fleet-form {
    .import-checkbox {
        margin-bottom: 8px;
        > span {
            padding-top: 0;
        }
    }
}