.menu-range {
	margin: 2px;

	.MuiFormLabel-root.MuiInputLabel-root {
		padding-bottom: 3px;
	}

	.MuiInputBase-root {
		font-size: 11px;

		.MuiSelect-root.MuiSelect-select {
			padding-bottom: 5px;
			padding-top: 5px;
			box-shadow: 0px 2px 3px #0000000d;
			border: 1px solid #afc3d2;
			border-radius: 4px;
		}

		.MuiSelect-icon {
			top: unset;
		}
	}
}
