.dualList {
    .table-result {
        height: 50vh;
        overflow: auto;

        th {
            padding: 1px 5px;

            p {
                font-weight: bold;
            }
        }

        td {
            div {
                display: flex;
            }
            span {
                padding: 0 !important;
                text-transform: capitalize;
            }
        }
    }
}
