.sugestions {
	li {
		color: #243782;
	}

	.noBullet {
		list-style: none;
		margin-left: -15px;
	}

	.invalid {
		color: red;
	}
}

.inputFilled {
	background: #dddddd;
	border-radius: 4px;
	height: 40px;

	.MuiInputLabel-root {
		// transform: scale(.8);
		top: -9px;
		left: 11px;
	}

	.MuiFormLabel-filled {
		top: 2px !important;
	}

	.MuiFormLabel-root.Mui-focused {
		top: 2px;
		color: rgba(0, 0, 0, 0.54);
	}

	input {
		margin: -4px 0 0 11px;
	}

	.MuiInput-underline:before,
	.MuiInput-underline::after,
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: none;
	}
}

.form-container {
	width: 100%;

	.button-login {
		width: 50px;
		background: #243782 !important;
		border-radius: 3px;
		opacity: 1;
		// padding: 20px;
	}

	.text-form {
		padding: 20px;
		padding-left: 0;
	}

	.MuiButton-label .MuiTypography-root {
		text-align: center;
		font: Bold 16px/15px Trebuchet MS;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
	}

	.box-remember {
		display: flex;
		justify-content: space-between;
		text-align: right;
		font: Regular 14px/17px Trebuchet MS;
		letter-spacing: 0px;
		color: #243782;
		opacity: 1;
		padding-left: "0";
	}
}
