.label-with-sublabel {
	display: flex;
    flex-direction: column;
    
    :nth-child(1) {
		font-weight: bold;
    }
    
	:nth-child(2) {
		font-size: 10px;
		line-height: 0.5;
		margin-bottom: 1.25em;
	}
}

.odometer-history-list {
	margin-top: 16px !important;
	height: 100%;
	box-sizing: border-box;
	padding: 0.7rem 0.5rem 0 0.5rem;
	border: 1pt solid #cad7e1;
	border-radius: 5px;

	.table-result {
		th {
			padding: 1px 5px;

			p {
				font-weight: bold;
			}
		}

		td {
			div {
				display: flex;
			}
			span {
				padding: 0 !important;
				text-transform: capitalize;
			}
		}
	}
}

.edit-vehicle {
	padding: 0.75rem;
}