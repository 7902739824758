.gridVehicleLists {
	display: flex;
	justify-content: stretch;
	align-items: stretch;

	.group-items .children {
		height: unset;
	}

	.card-vehicles-list {
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 0;
		.itemTitle {
			width: auto !important;
		}

		.boxRangeVehicles {
			display: flex;
			justify-content: space-around;
			width: 25%;
		}

		.table-result {
			height: unset;
			max-height: 40vh;
			padding-bottom: 0;
			th {
				padding: 1px 5px;

				p {
					font-size: 12px !important;
					font-weight: bold;
				}
			}

			td {
				div {
					display: flex;
				}
				span {
					padding: 0 !important;
					font-size: 12px !important;
					text-transform: capitalize;
				}
			}
		}
	}

	.card-vehicles-list-tabs {
		padding: 0;
		height: 100%;
	}
}

.vehicle-form-driver-autocomplete {
	> div {
		padding: 0.7rem 0.5rem;
		h6.MuiTypography-root {
			font-weight: normal;
		}
	}
	p.MuiFormHelperText-root {
		font-size: 10px;
	}
}