.MuiContainer-maxWidthMd {
	max-width: none !important;
	min-width: 960px;
}

.login-main {
	height: 100vh;
	display: flex;

	.login-img {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 85px;
		}
	}

	.login-paper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.login-tab {
			width: 380px;
			min-height: 50vh;
			padding-top: 2rem;
		}

		.MuiFormHelperText-root.Mui-error {
			position: absolute;
			bottom: -1.3rem;
			margin: 0;
		}

		.button-form {
			padding-top: 1.5rem;
		}

		.MuiTab-wrapper {
			text-transform: capitalize;
		}
	}
}
