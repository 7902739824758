.powered-by-google-box {
    padding: 0.5rem 1rem;
    padding-top: 0.2rem;
    text-align: right;

    img {
        height: 15px;
    }
}
.MuiAutocomplete-root {
    .MuiFormControl-root.text-field-places {
        input {
            font-size: 14px;
		}
		
        &.borded {
            .MuiInputBase-root.MuiOutlinedInput-root {
				border: 1px solid #afc3d2;
				padding: 0.3rem 0.5rem ;
            }
        }

        fieldset {
            display: none;
        }
    }
}

.places-options .MuiTypography-root{
	font-size: 14px;
}
