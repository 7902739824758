@import "variables.scss";
@import "fonts.scss";
@import "image.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: unset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

body,
html,
#root {
    margin: 0;
    padding: 0;
    background: #f8f8f8;
    font-size: 14px;
    font-family: Trebuchet MS, sans-serif;
    font-weight: normal;
}

.ceabs-layout {
    .MuiButtonBase-root {
        text-transform: capitalize;
    }

    .MuiButton-outlined.MuiButton-outlinedSecondary {
        border-radius: 5px;
    }

    .fuso-horario p {
        font-size: 14px;
        font-family: Trebuchet MS, sans-serif;
        font-weight: normal;
        line-height: 16.80000114440918px;
    }

    .card.form-card {
        margin: 0;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0px 2px 15px #00000012;
        padding: 1.5rem;

        &.notPadded {
            padding: 0;
        }

        .boxTitle {
            margin-top: 0;
        }
    }

    .register-title {
        display: flex;
        align-items: center;
        font-size: 20px;

        img {
            margin-right: 0.5rem;
            width: 20px;
            height: 20px;
        }

        .MuiTypography-root {
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.53px;
            color: rgba(61, 85, 100, 1);
            display: flex;
        }
    }

    .comeback-button {
        background-color: transparent;
        font-weight: bold;
        font-size: 18px;
        color: #3d5564;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .ceabs-register-form {
        .register-field,
        .button-form {
            padding: 0.7rem 0.5rem;
            height: 100%;
            box-sizing: border-box;

            justify-content: flex-end;
            display: flex;
            flex-direction: column;
        }

        .button-form {
            .MuiButton-label {
                font-weight: bold;

                img {
                    margin-right: 0.5rem;
                }
            }

            .cancel-button {
                background-color: #afc3d2;

                .MuiButton-label {
                    color: #fff;
                }

                img {
                    width: 10px;
                }
            }

            .save-button img {
                width: 13px;
            }
        }

        .register-field {
            .register-field-label {
                color: #3d5564;
                font-size: 14px;
                font-weight: normal;
            }

            .MuiFormControl-root {
                margin: 0;
                min-height: 40px;
            }

            .MuiInputBase-root {
                font-size: 14px;
                background-color: rgba($color: #afc3d2, $alpha: 0.2);
                height: 100%;

                fieldset {
                    display: none;
                }

                &.Mui-error {
                    border: 1px solid red;
                }
            }

            .MuiFormHelperText-root {
                position: absolute;
                top: 3rem;
                font-size: 12px;
                margin-left: 0px;
            }

            .MuiSelect-icon {
                margin-top: 0.2rem;
            }

            .MuiAutocomplete-endAdornment {
                top: calc(50% - 10px);
            }

            &.register-field-checkbox {
                .MuiIconButton-label .MuiSvgIcon-root {
                    height: 24px;
                    width: 24px;
                }

                span.MuiButtonBase-root {
                    padding-bottom: 4px;
                }

                span.MuiTypography-root {
                    font-size: 14px;
                    padding-top: 6px;
                }
            }

            &.register-switch {
                .MuiFormControlLabel-label.MuiTypography-root {
                    color: #3d5564;
                }
            }

            &.regsiter-array-field {
                justify-content: flex-start;
            }

            &.no-padding {
                padding: 0;
            }
        }
    }

    .expansion-children-card {
        box-sizing: border-box;

        .boxTitle {
            box-sizing: border-box;
        }

        .children {
            height: calc(100% - 2rem);
            min-height: 200px;
            box-sizing: border-box;
        }
    }

    .map-card {
        height: 100%;
        box-sizing: border-box;

        .boxTitle {
            box-sizing: border-box;
        }

        .children {
            height: calc(100% - 2rem);
            box-sizing: border-box;
        }
    }

    .add-button {
        .MuiButton-label {
            .MuiButton-startIcon {
                img {
                    width: 20px;
                }
            }

            font-size: 12px;
            font-weight: bold;
        }
    }

    .span-actions {
        padding: 3px;
        display: flex;
        span {
            display: flex;
            align-items: center;

            svg {
                margin-right: 4px;
            }
        }
    }

    .card-container {
        display: flex;
    }

    .container-aditional-group-card {
        padding: 1rem;
    }

    .container-group-card {
        display: flex;
        width: 100%;
        min-height: 100px;
        padding: 1rem;
        box-sizing: border-box;

        .container-group-card-item {
            flex: 1;
            width: 50%;
            position: relative;

            .card {
                margin: 0;
                height: 100%;
                box-sizing: border-box;
            }
        }
    }

    .no-padding {
        padding: 0;
    }

    .strong-plate {
        font-weight: bold;
        font-size: 18px;
    }

    .card.card-refactor {
        padding: 0;

        > .boxTitle {
            font-size: 20px;
            min-height: calc(70px - 1.5rem);

            margin: 0;
            border-bottom: 0;
            box-sizing: border-box;

            padding: 1.5rem;
            padding-top: 1.3rem;
            padding-bottom: 0.3rem;
            min-height: 70px;
            // border: 1px solid blue;
        }

        > .card-title-divider {
            display: inline-flex;
            border-bottom: 1px solid rgba($color: #afc3d2, $alpha: 0.3);
            box-sizing: border-box;

            width: calc(100% - 3rem);
            margin: 0 1.5rem;
        }

        > .children {
            padding: 1.5rem;
            // border: 1px solid rgb(199, 187, 207);
            height: calc(100% - 71px);
            box-sizing: border-box;
        }

        &.notPadded {
            > .children {
                padding: 0;
            }

            > .card-title-divider {
                width: calc(100% - 3rem);
                margin: 0 1.5rem;
            }
        }
    }

    .google-maps-card.map-card-refactor {
        .mapFilter {
            padding: 1.5rem;
            .MuiButton-root.button-popover .MuiButton-label {
                font-size: 14px;
            }
        }
    }

    .refresh-interval-refactor.refreshInterval {
        width: fit-content;
        display: flex;
        align-items: center;

        .text {
            font-size: 16px;
            display: flex;
            align-items: center;
        }

        .divider {
            display: block;
            height: 16px;
            margin: 0 10px;
            border-left: 1px solid rgba(175, 195, 210, 0.33);
        }

        .iconRefresh {
            margin: 5px;
            font-size: 21px;
        }
    }

    .card-group-refactor.card {
        .boxTitle {
            font-size: 20px;
        }

        .card-group-header {
            align-items: center;
            .card-group-search {
                height: 40px;
                input {
                    font-size: 14px;
                }
            }
        }

        .table-result {
            table {
                tr,
                td {
                    font-size: 16px;
                }
            }
        }
    }

    .boxSearchComp.search-input-refactor {
        height: 40px;

        input {
            font-size: 14px;
        }
    }

    .menu-range.menu-range-refactor {
        height: 40px;
        box-sizing: border-box;
        margin: 0;

        label.MuiFormLabel-root.MuiInputLabel-root {
            padding-bottom: 1px;
            font-size: 14px;
        }

        .MuiInputBase-root .MuiSelect-root.MuiSelect-select {
            min-height: unset;
            height: 23px;
            box-sizing: border-box;
        }
    }

    .MuiButtonBase-root.report-save-button.report-save-button-refactor {
        height: 40px;

        .MuiButton-label {
            font-weight: bold;
            font-size: 16px;
            line-height: 15px;

            img {
                width: 21px;
            }
        }
    }

    .flex-align-center {
        display: flex;
        align-items: center;
        align-content: center;
    }

    .flex-justify-center {
        display: flex;
        justify-items: center;
        justify-content: center;
    }
}

.spaceSearchReportObjectPortal{
    min-width: 300px;
    display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
}

.Toastify__toast-container {
    .Toastify__toast {
        box-shadow: 0px 0px 8px 2px rgba($color: #000000, $alpha: 0.5);
        font: Regular 16px/19px Trebuchet MS, sans-serif;
    }
}

/*  REFACTOR TAMANHO FONTES  */

.menu-label-range-refactor {
    font-size: 14px !important;
}

input.search-refactor {
    font-size: 14px;
}

.tab-label-refactor {
    font-size: 20px !important;
    white-space: nowrap;
}

.save-button-refactor img {
    width: 21px !important;
}

.cancel-button-refactor img {
    width: 16px !important;
}

.button-form-refactor {
    .MuiButton-label {
        font-weight: bold;
        font-size: 16px !important;
    }
    button {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        height: 40px;
    }
}

.link-import {
    font-style: italic;
    display: flex;
    padding: 10px 0 0 0;
    align-items: flex-start;
    .iconImportDw {
        margin: 0 5px 0 0;
        height: 25px;
        width: 25px;
    }

    width: 100%;
    cursor: pointer;
    p {
        font-size: 14px;
        color: #afc3d2;
    }
}

.link-import:hover {
    opacity: 0.8;
}

button.import-cancel-button {
    display: flex !important;
    flex: 2 !important;
    background-color: #afc3d2 !important;
    margin-right: 15px !important;
    span p {
        font-size: 16px;
    }
    img {
        width: 12px;
        margin: 5px;
    }
}

button.import-confirm-button {
    span p {
        font-size: 16px;
    }
}

.close-light-gray-icon {
    width: 26px;
    height: 26px;
}

.import-green-icon {
    width: 20px;
    height: 20px;
}

.boxTitle-refactor {
    font-size: 20px !important;
    .itemTitle {
        margin-bottom: 10px;
    }
}

.import-checkbox span svg {
    height: 24px;
    width: 24px;
}

.comeback-top {
    padding-right: 0 !important;
}

.cardListObject {
    padding: 0;
    margin: 0;
    background: none;
    box-shadow: none;
}

.contentCard {
    height: 100%;
    // border: 1px solid red;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .content-table-card {
        padding: 10px 10px 0px 0px;

        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.export-button {
    span.MuiButton-label {
        font-size: 16px;
    }
}

li.export-menu-item {
    font-size: 14px;
}

.header-transform-none {
    th.MuiTableCell-head span {
        text-transform: none;
    }
}

.nowrap-error-msg {
    p.MuiFormHelperText-root.MuiFormHelperText-filled {
        white-space: nowrap;
    }
}

.cookie-link {
  color: cornflowerblue;
}
