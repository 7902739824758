.forms{
    label{
        font-size: 10px;
    }
    .MuiInputBase-root.Mui-disabled{
        background-color: rgba(175, 195, 210, 0.2);
        border:none;
    }
}

.boxTableAlerts{
  .table-result{
    height: 28vh;
  }
}

#label-history-alert {
  font-size: 14px;
  font-weight: bold;
}

#historic-form {

  .register-field {
    .register-field-label {
      line-height: 1.75;
    }
  }

  .MuiGrid-container {
    align-items: flex-end;;
  }
}