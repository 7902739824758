@font-face {
	font-family: "Trebuchet MS";
	src: url("../fonts/trebuchet-ms.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Trebuchet MS";
	src: url("../fonts/trebuchet-ms-bold.ttf");
	font-weight: bold;
	font-style: normal;
}
