.dataVhicleStyle {
    .boxData {
        display: flex;
        justify-content: space-between;
        padding: 10px 0 10px;
        overflow-x: auto;
        width: 100%;

        .viewItemdata {
            .column {
                color: #afc3d2;
            }

            .data {
                color: #3d5564 !important;
            }
        }

        .viewItemdata:last-child {
            margin: 0;
        }
    }
}

.boxPrivateMode {
    padding-top: 1rem;

    .privateModeTitle .MuiTypography-root {
        border-bottom: 1px solid rgba(175, 195, 210, 0.3);
        font-size: 20px;
        font-weight: bold;
        color: rgba(61, 85, 100, 1);
        padding-top: 1.3rem;
        padding-bottom: 1rem;
        letter-spacing: 0.53px;
    }

    .privateModeBody {
        padding-top: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            padding-right: 1.5rem;
            display: flex;
            align-items: center;
        }

        .privateModeLoading {
            position: relative;
            width: 100%;
            height: 3rem;
            padding: 0;
        }

        .privateModelabelStatus {
            display: flex;
            align-items: center;

            .privateModelock {
                padding-right: 1rem;
                color: rgba(61, 85, 100, 1);
            }

            .semaphore {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background-color: #eee;
                margin-right: 1.3rem;

                &.active {
                    background-color: rgba($color: green, $alpha: 0.6);
                    box-shadow: 0px 0px 8px 2px rgba($color: green, $alpha: 0.4);
                }

                &.inactive {
                    background-color: rgba($color: red, $alpha: 0.6);
                    box-shadow: 0px 0px 8px 2px rgba($color: red, $alpha: 0.4);
                }
            }

            .labelprivateModelStatus .MuiTypography-root {
                color: rgba(61, 85, 100, 1);
                font-size: 17px;
                font-weight: bold;
                line-height: 18px;
                padding-right: 1rem;
            }
        }

        .privateModeDate {
            color: rgba(61, 85, 100, 0.7);
            font-size: 13px;
            line-height: 18px;
        }

        .privateModeButton {
            margin-left: 1rem;
            padding: 0.5rem 1rem;
            .MuiButton-label {
                font-size: 15px;
                font-weight: bold;
            }
        }

        .privateModelRefresh {
            float: right;
        }
    }
}
