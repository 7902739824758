.alertCardList {
    .table-result {
        overflow-y: auto;
        table {
            overflow: hidden;
        }
    }

    height: 120vh;
    overflow: auto;
    padding-right: 8px;
}

.alert-conclude-button .MuiButton-label {
    font-size: 16px;
    font-weight: bold;
}

.main-accordion .MuiAccordionDetails-root {
    padding: 0;
}

.itemAlerts {
    display: flex;
    flex-direction: column;
    flex: 1;

    .MuiPaper-root {
        padding: 0 !important;
    }

    .pagination {
        display: none;
    }

    .table-result th {
        padding: 0;
        p {
            padding-left: 10px;
        }
    }
}

.conditionsList {
    overflow: auto;
    padding: 0.5rem 1rem;

    p.MuiTypography-root {
        font-size: 14px;
        color: #3d5564;
    }
}

.styleBtnTable p.MuiTypography-root {
    margin: 0;
    display: flex;
    flex: 1 1;
    font-size: 14px;
}

.boxBtnItem {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 11px;
        font-weight: bold;
    }
}

.itemBtnAccordion {
    padding: 0 !important;
    display: flex !important;
    width: auto !important;
    align-items: center !important;
    justify-content: center !important;
}
