.routes-grid {
    min-height: 30vw;
}

.resultGridRoutes .table-result td div {
	text-align: start;
	padding: 0 !important;
}

.resultGridRoutes .table-result td div strong {
	font-size: 14px;
}

.resultGridRoutes .table-result td {
	padding: 0px 0px 0px 5px !important;
}

.resultGridRoutes .table-result th {
	padding: 5px 0 0 5px !important;
}

.resultGridRoutes .table-result th p {
	font-size: 14px !important;
}

.resultGridRoutes .table-result td .MuiIconButton-root {
	padding: 0px !important;
}

.resultGridRoutes .pagination {
	justify-content: center !important;
	margin-top: 30px;
}

.routes-action-column {
	padding-left: 10px;
	display: flex;
	width: 100%;
	justify-content: center;
}
