.recoverPass {
    #email-recover {
        font-size: 14px;
        font-family: "Trebuchet MS";
    }

    .MuiButtonBase-root {
        padding-left: 0;

        .MuiButton-label {
            text-transform: capitalize;
            font-size: 18px;
            font-family: "Trebuchet MS";
            color: #afc3d2;
        }
    }

    .classMainTabRecover {
        .MuiButtonBase-root {
            padding: 0;

            p {
                font-size: 25px;
                white-space: nowrap;
                font-family: "Trebuchet MS";
            }

            span.MuiButton-label {
                font-family: "Trebuchet MS";
                font-size: 18px;
                font-weight: bold;
                text-transform: none;
                color: #fff;
            }
        }

        .MuiTabs-root .MuiTabs-indicator {
            background-color: #243782;
            width: 120px !important;
        }

        .boxFormRecover {
            display: flex;
            flex-direction: column;
            height: 20vh;
            justify-content: space-between;
        }

        .text-form {
            display: flex;
            flex-direction: column;
            flex: 1;
            .margin-form {
                margin: 20px 0 20px 0;
            }

            .MuiFormLabel-root.Mui-focused {
                color: #3d5564 !important;
            }

            .MuiFormLabel-root.Mui-error {
                color: #3d5564 !important;
            }

            .MuiInput-underline:after {
                border-color: #243782;
            }

            .MuiInput-underline.Mui-error:after {
                border-color: #fc5c75;
            }

            .MuiFormLabel-root.Mui-error {
                border-color: #fc5c75;
            }

            .MuiFormHelperText-root.Mui-error {
                color: #fc5c75;
            }
        }

        .btnSendForm {
            height: 40px;
            border-radius: 3px;
            box-shadow: none;
            background-color: #243782;
        }

        .textInfo {
            padding: 15px 0px 0 0px;

            p {
                font-size: 13.4px;
                color: rgba(61, 85, 100, 0.6);
                font-family: "Trebuchet MS";
            }
        }
    }

    .btnBack {
        position: relative;
        top: -35px;
        color: #afc3d2;

        p {
            font-size: 16px;
        }
    }
}
