.rotate-plus-45 {
	transform: rotate(45deg);
}

.rotate-plus-90 {
	transform: rotate(90deg);
}

.rotate-plus-180 {
	transform: rotate(180deg);
}

.rotate-minus-45 {
	transform: rotate(-45deg);
}

.rotate-minus-90 {
	transform: rotate(-90deg);
}

.rotate-minus-180 {
	transform: rotate(-180deg);
}
