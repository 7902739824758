.ceabs-layout .ceabs-main .ceabs-content .container-map-view.MuiContainer-root.MuiContainer-maxWidthLg {
    padding: 1.5rem;
}

.mapScreen .map-card {
    height: 58vh !important;
}

.mapViewMap {
    .mapScreen .map-card {
        height: 100% !important;
    }
}

.vertical,
.verticalInverse {
    .viewMapVertical {
        height: 60vh;
    }
}

.container-map-view {
    max-width: inherit !important;
}

.mapViewList {
    ul.MuiPagination-ul {
        flex-wrap: unset;
    }
}

.expansionGrid {
    // &.horizontal {
    // 	.mapViewList {
    // 		margin-left: 8px;
    // 	}

    // 	.mapViewMap {
    // 		margin-right: 8px;
    // 	}
    // }

    // &.horizontalInverse {
    // 	.mapViewList {
    // 		margin-right: 8px;
    // 	}

    // 	.mapViewMap {
    // 		margin-left: 8px;
    // 	}
    // }
}
