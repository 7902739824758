.main-group-register{
    .box-group{
        margin: 25px 8px;
    }
    .table-result{
        height: 28vh !important;
        .MuiTableContainer-root{
			height: 28vh;
		}
    }
}

#register-group-drivers-save-button {
    padding: 5px 16px;
}

#register-group-drivers-form {
    .button-form {
        padding-bottom: 4px;
    }
}