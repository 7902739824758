#group-vinculated-points-finder {
    tr:nth-child(even) {
        background-color: white;
    }
}

#points-group-actions-column {
    padding-left: 5px;
    display: flex;
    span {
        display: flex;
        font-size: 12px;
    }
}
