.searchPoints {
	width: 80%;
	margin-bottom: 15px;
}

.resultGridPoints .table-result td div {
	text-align: start;
	padding: 0 !important;
}

.resultGridPoints .table-result td {
	padding: 0px 0px 0px 5px !important;
}

.resultGridPoints .table-result th {
	padding: 5px 0 0 5px !important;
	&:first-child {
		padding-left: 0 !important;
		display: flex;
		justify-content: center;
		border: 1px solid #f0f4f7;
		border-left: none;
		border-top: none;
	}
}

.resultGridPoints .table-result th p {
	font-size: 14px !important;
}

.resultGridPoints .table-result td .MuiIconButton-root {
	padding: 0px !important;
}

.resultGridPoints .pagination {
	justify-content: center !important;
	margin-top: 30px;
}
