#export-types-menu {
	.MuiMenu-paper {
		top: 158px !important;
		left: 90% !important;
		width: 156px;
	}

	@media only screen and (max-width: 1900px) {
		.MuiMenu-paper {
			left: 86% !important;
		}
	}
}

#register-drivers-buttons {
	margin-bottom: 10px;
	display: flex;
	margin-left: 1rem;
}

#drivers-actions-column {
    padding-left: 5px;
    display: flex;
	font-size: 12px;
}