.card-tutorial{
    margin: 10px 8px 10px 8px;
    padding: 20px;
    box-sizing: inherit;
    height: 350px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        margin: 10px 0;
        font-size: 14px;
        color: #afc3d2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
    .title{
        margin-bottom: 10px;
        padding: 5px 0;
        color:#3D5564;
        border-bottom: 1px solid #afc3d2;
    }
    .img-tutorial{
        width:100%;
        height: 150px;
        object-fit: cover;
    }
    .slick-list{
        height: 100%;
    }
    
}