.cardTabTopTen {
    margin: 0px 0px 20px 0px;
    padding: 10px 10px 20px;
}

.cardAnalyzeTop {
    .classMainTab {
        .MuiTab-root {
            min-width: 23% !important;
        }

        .gridChildrenBoxTab {
            height: 54vh;
        }

        .tableGridData {
            .imgTop10 {
                height: 42px;
                width: 42px;
            }

            tr td {
                padding: 10px 0px 5px;
            }
        }
    }
}

.classMainTab {
    .MuiTabs-root {
        padding: 0px;
    }

    .childrenPanelTab {
        padding: 10px 0px 0px;
        margin-top: 10px;
    }

    .tableGridData {
        .imgTop10 {
            height: 32px;
            width: 32px;
        }

        .imgTop10Driver {
            height: 32px;
            width: 32px;
            margin-left: 5px;
        }
    }

    .fontDist {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 18px;
    }

    .progressBar-label {
        padding: 0 1rem;
    }

    .progressAnalyze {
        .progressBox {
            flex: 7;
        }

        .fontDist {
            flex: 3;
        }
    }
}
