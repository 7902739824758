.eventsObjectMap {
	.children {
		height: calc(100% - 2.1rem)
	}

	.card-report-map {
		height: 100%;
		.map-card  {
		  height: 100% !important;
		}
	}
}

.vertical, .verticalInverse {
	.eventsObjectMap {
		height: 58vh;
	}
	.eventsObjectMap.expandedCard {
		height: 100%;
	}
}

.events-period-button {
	display: flex;
	justify-content: center;
	width: 100%;
	div {
		width: auto;
	}
}

.table-result td p.exhibition-mode-events {
	font-size: 12px;
	color: #3d5564;
	text-align: center;
	margin: 0;
	width: 100%;
}

#report-events {
	.report-filter, .card-report {
		margin-left: 8px;
		margin-right: 8px;
	}
}