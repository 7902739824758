.table-tab {
	color: #3D5564;

	thead th {
		font-weight: bold;
		font-size: 20px;
		color: #3D5564;
	}

	tbody {
		color: #3D5564;


		tr {
			transition: 0.2s;


            th.MuiTableCell-root {
				font-size: 18px;
                color: inherit;
                cursor: pointer;
                padding: 1rem;
            }

			.arrow {
				position: relative;
				padding: 0;
            }

			&.active {
                transition: 0.2s;

                th.MuiTableCell-root {
					border: none;
					font-weight: bold;
				}

				.arrow {
	                background-color: inherit;
                    border-color: inherit;

					span {
						border-top: 10px solid transparent;
						border-bottom: 10px solid transparent;

						border-left: 10px solid;
						border-left-color: inherit;

						position: absolute;
						top: calc(50% - 10px);
					}
				}
			}
		}
	}

	th.MuiTableCell-root {
		border-right: 1px solid rgba(224, 224, 224, 0.5);

		&:last-child {
			border-right: none;
		}
	}
}
