.cardBaseComparative.card.card-refactor {
    background: none;
    box-shadow: none;
    margin: 0;
    padding-top: 5px;
    padding-left: 0;
    padding-right: 0;

    & > .children {
        & > div.MuiGrid-root div.card {
            height: calc(100% - 2rem);
        }
    }

    .boxTitle {
        border: none;
    }

    > span.card-title-divider {
      display: none;
    }

    .cardTitleBase {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .cardBaseKmRunTable {
        td {
            padding: 0 !important;

            div {
                text-align: start;
                padding: 0 5px;
            }

            span {
                font-size: 14px;
            }
        }

        th {
            p {
                font-size: 14px !important;
                font-weight: bold;
                padding: 5px;
                text-align: start;
            }
        }

        .custom-table .MuiTableCell-root {
            padding: 0;
        }

        .loadingGridData {
            width: 100%;
            height: 100%;
        }
    }
}
