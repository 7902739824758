.main-accordion {
	padding-bottom: 10px;
}
.main-accordion .MuiAccordion-root {
	border-radius: 3px !important;
	box-shadow: none;
}

.main-accordion .MuiAccordionSummary-root {
	background: #eef2f5;
	min-height: 30px;
	height: unset;
	border: 1px solid #afc3d2;
	border-radius: 3px 3px 0px 0px;

	.MuiAccordionSummary-content {
		margin: 0.5rem 0;
	}
}

.main-accordion .MuiAccordionSummary-root.Mui-expanded {
	min-height: 0px;
}

.fieldTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.fieldTitle img {
	margin: 5px;
	margin-right: 10px;
}

.titleStyles {
	font-size: 16px !important;
	color: #3d5564;
}

.main-accordion .MuiCollapse-container {
	border: 1px solid #ccc;
	border-top: 0px;
	border-radius: 0px 0px 3px 3px;
}
