.breadcrumbs {

    width: 100%;
    padding: 0px;
    box-sizing: border-box;

    & > div{
        width: 100%;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(175, 195, 210, .56);
    }

    .title {
        font-family: 'Trebuchet MS', sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.53px;
        color: rgba(61, 85, 100, 1);
        float: left;
        margin-right: 10px;
        margin-top: -8px;
    }

    .link,
    .MuiBreadcrumbs-separator {
        font-family: 'Trebuchet MS', sans-serif;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0px;
        color: rgba(175, 195, 210, 1);
        text-decoration: none;
        cursor: default;
    }

    .link[href^="/"]:hover{
        text-decoration: underline;
        cursor: pointer;
    }

}