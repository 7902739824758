.loading-panel {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #fff, $alpha: 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 20;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}
