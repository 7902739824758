.ceabs-menu-list {
	width: 90px;

	.menuItem {
		height: 90px;
		border-bottom: 1px solid #f3f3f3;

		.MuiListItemText-root {
			flex: initial;
		}
  }
  .dot {
	height: 8px;
	width: 8px;
	background-color: #fff;
	border-radius: 50%;
	display: block;
	position: absolute;
	top: 0.7rem;
	right: 1.5rem;
  }
  .notPermission{
    display: none;
  }

	.MuiList-padding {
		padding: 0;
	}

	.MuiButtonBase-root {
		padding-left: 0.7rem;
		padding-right: 0.7rem;

		.MuiTypography-root {
			color: #fff;

			font-size: 16px;
			line-height: 29x;
			font-weight: normal;
			text-decoration: none;
		}

		&.active {
			.MuiTypography-root {
				color: #fff;
			}

			background-color: rgba(0, 0, 0, 0.5);
		}
	}
}
