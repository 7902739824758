.refreshInterval {
    display: inline-flex;
    justify-content: space-between;
    width: 145px;
    right: 0;
    z-index: 100;

    .text {
        color: rgba(175, 195, 210, 1);
        font-weight: bold;
        font-size: 12px;
        line-height: 23px;
        font-family: "Trebuchet MS";
    }

    .iconRefresh {
        color: #3d5564;
        margin-top: 5px;
    }

    .refresh-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;

		&:hover {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: #afc3d2;
        }
    }
}
