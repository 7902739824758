.root {
	width: 100%;
	background-color: white;
	padding: 0;
	margin-bottom: 10px;
}

.cardItem {
	display: flex;
	padding: 0px 0px 0px 2px;
	height: auto;
}

.cardItemColumn {
	flex: 3;
	background: rgba($color: #afc3d254, $alpha: 0.13);
	flex-direction: row-reverse;
	height: 100%;
	align-items: center;
	color: #afc3d2;
}

.cardItemValue {
	flex: 6;
	flex-direction: row;
	height: 100%;
	align-items: center;
	padding-left: 0.5rem;
	color: #3d5564;
}

.border {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	position: relative;
}

.borderVehicle {
	display: flex;
	position: absolute;
	width: 90%;
	left: 6.5%;
	border-bottom: 0.1px solid rgba($color: #afc3d2, $alpha: 0.33);
	z-index: 1;
}

.borderMain .borderVehicle:last-child {
	/*border-bottom: none;*/
}
