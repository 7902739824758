.boxTab {
	.btnKm {
		margin: 0 !important;
		border: none !important;
		padding: 15px !important;
		border-radius: 4px !important;
		text-transform: none !important;
	}

	.btnFont {
		font-size: 22px !important;
		color: #3d5564;
		flex: 1;
		display: flex;
		justify-content: flex-start;
		padding-left: 20px;
	}

	.gridChildrenBoxTab {
		overflow-y: auto;
		height: 40vh;
	}

	.gridChildrenBoxTab {
		overflow-y: auto;
		height: 40vh;
	}
}
