@media all {
	.page-break {
		display: none;
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		margin: 0 !important;
		-webkit-print-color-adjust: exact;
		// transform: scale(1);
	}
}

@media print {
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
    }
	

    .card.card-refactor {
        break-inside: avoid !important;
		height: auto;
		zoom: 60% !important;
	}

	.MuiGrid-root {
        break-inside: avoid !important;
	}

}

@page {
	size: auto;
	margin:: 0.5cm;;
}