.expansion-map-card.expandedCard .mapScreen {
    height: 100%;
    .map-card {
        height: 100% !important;
    }
}

#container-finder-routes-view {
    .vertical,
    .verticalInverse {
        .card:not(.expandedCard) {
            height: auto;
        }
    }
}

#routes-search-header {
    .card-group-search {
        margin: 0;
    }
}
