.paperlist {
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 2px;
	opacity: 1;
	margin-bottom: 1px;
}

.paperlistActive {
	background: rgba(175, 195, 210, 0.15);
	border-radius: 2px;
	opacity: 1;
	margin-bottom: 1px;
}

.textDetail {
	text-align: left;
	font: normal normal normal 14px/20px Trebuchet MS;
	letter-spacing: 0px;
	color: #afc3d2;
	opacity: 1;
	margin: 20px;
}

.titleCommunicate {
	text-align: left;
	font: normal normal bold 17px/14px Trebuchet MS;
	letter-spacing: 0px;
	color: #3d5564;
	opacity: 1;
	padding: 15px;
}

.paperlistDetail {
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 2px;
	opacity: 1;
	margin-bottom: 1px;
	box-shadow: 0px 0px 3px 0px #00000033;
}
