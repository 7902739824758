.container {
	display: flex;
	align-items: flex-end;
}

.field {
	display: flex;
	flex-direction: column;
	margin: 0px 15px 0px 0px;
	width: 20%;
}

.field-input {
	background: none;
	width: 60px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.field div input {
	padding: 10px;
}

.period-date-picker p {
	margin: 0 !important;
	position: absolute;
	top: 40px;
	width: 100%;
}

// .btn-confirm {
// 	display: flex !important;
// 	justify-content: center !important;
// 	align-items: center !important;
// 	border-radius: 3px !important;
// 	padding: 10px 0px !important;
// 	width: 100%;
// 	opacity: 1;
// 	height: 35px;
// }

#loading-object {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 55%;
	margin: 5px;
}

.spaceSearch {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
	padding: 10px 0px 0px 0;
}

.tabActiveTable {
	position: absolute;
	top: -60px;
	left: 10rem;
	width: 35%;
}

.kmTravel {
	text-align: left;
	font: normal normal normal 12px/15px Trebuchet MS;
	letter-spacing: 0px;
	color: #3d5564;
	opacity: 1;
	margin-right: 5px;
}

.contentCardTravel {
	height: 65vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0px 10px 0px 0px;
}

.btn-confirm {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	padding: 10px 0px;
	width: 100%;
	opacity: 1;
	height: 30px;
}
