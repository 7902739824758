.Toastify__toast-body {
    white-space: pre-line;
}

.table-fleets {
    overflow: auto;
    max-height: 300px;
    margin-top: 10px;
    .MuiPaper-root {
        padding-top: 0 !important;
    }
}

.userPermissions {
    margin-top: 20px;
    padding: 0 !important;
    border-radius: 4px;
    border: 0.5px solid #cce;

    .main-accordion {
        padding-bottom: 0;

        .MuiAccordionSummary-root {
            border: none;
            border-radius: 4px;
            margin: 0;
        }
    }

    .conditionsList {
        height: 100px;

        .MuiFormControlLabel-label {
            font-size: 14px;
        }

        .MuiCheckbox-root {
            padding: 5px 20px 5px 22px !important;
        }
    }
}

.card-users-list {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15) !important;

    .itemTitle {
        width: auto !important;
    }

    .titleBtn {
        display: flex;
        width: 88%;
        align-items: center;
        justify-content: flex-end;

        .boxSearchComp {
            flex: none;
            margin: 0 15px;
        }
    }

    .table-result {
        th {
            padding: 1px 5px;

            p {
                font-size: 12px !important;
                font-weight: bold;
            }
        }

        td {
            div {
                display: flex;
            }
            span {
                padding: 0 !important;
                font-size: 12px !important;
                text-transform: capitalize;
            }
            p {
                font-size: 11px !important;
                text-align: start;
            }
        }
    }
}

.cardUserTab {
    padding: 0;

    .children {
        height: auto;
    }
}

.boxCardUserAdd {
    .box-group {
        margin: 0;
    }
}

.boxFormAddUser {
    margin-bottom: 15px !important;

    .card {
        margin: 0;
    }

    .Mui-error {
        font-size: 10px;
    }

    #confirmPass-helper-text {
        margin-bottom: -16px;
    }
}

div.itemViewVehicles.MuiGrid-item {
    p {
        color: #3d5564;
        font-size: 14px;
        font-weight: bold;
    }
}

.txtButton {
    font-weight: bold;
    font-size: 14px;
    color: #fff;
}

.cancelButton {
    background: #afc3d2;
}

.userButton {
    border-radius: 2px;
}

.card-fleets-list-tabs {
    .itemTitle {
        display: none;
    }
    .MuiGrid-item {
        width: 100%;
    }

    .table-result {
        th {
            padding: 1px 5px;

            p {
                font-size: 13px !important;
                font-weight: bold;
                text-align: start;
            }
        }

        td {
            div {
                display: flex;
            }

            span {
                padding: 0 !important;
                font-size: 12px !important;
                text-transform: capitalize;
            }

            p {
                text-align: start;
            }
        }
    }
}

.cardEditUser {
    margin: 0;
    padding: 1rem;

    .boxFormEditUser {
        height: auto;
    }

    .boxGridBtn {
        display: flex;

        .MuiGrid-item {
            margin-right: 10px;
        }

        .txtButtonEdit {
            display: flex;
            flex: 1;
            font-weight: bold;
            font-size: 12px;
            text-transform: none;
            text-align: center;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                margin-right: 5px;
            }
        }
    }

    .btnPermission {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;

        .MuiGrid-item {
            margin-right: 0;
        }

        .btnSavePermission {
            margin-left: 10px;
        }
    }
}

#permissions-user-form, #user-register-form {
    .register-field-checkbox {
        padding: 0;
        height: unset;
    }
}

.register-user-tab-title {
    font-size: 20px !important;
    text-transform: none;
}

#user-register-form {
    div.register-field {
        padding-left: 0;
    }

    .register-switch {
      align-items: flex-start;
      padding: 0;
      padding-bottom: 10px;
      margin-top: -10px;
      label {
          margin-left: 0;
      }
  }
}

#register-user-permission-cancel-button {
  padding-left: 0;
}

#register-user-permission-save-button {
  padding-right: 0;
}

#register-user-permission-list {
  margin-top: 30px;
}

#register-user-permission-accordion {
  margin-bottom: 30px;
}