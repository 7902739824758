.report-tab {
	display: flex;
	border-bottom: 1px solid rgba($color: #afc3d2, $alpha: 0.5);

	.MuiTabs-root {
		flex: 1;

		.MuiButtonBase-root.MuiTab-root {
			font-weight: bold;
			font-size: 20px;
			line-height: 20px;
			padding-bottom: 1.3rem;
			color: #afc3d2;
			margin-right: 0.2rem;
			min-width: 139px;

			&.Mui-selected {
				color: #3d5564;
			}
		}
	}

	.container-clean-button {
		flex: 1.5;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		.MuiButtonBase-root {
			padding: 0.2rem 0.8rem;
			margin-bottom: 0.3rem;

			.MuiButton-label {
				span {
					display: block;
					img {
						width: 9px;
					}
				}
				font-size: 16px;
				font-weight: bold;
				color: #afc3d2;
			}
		}
	}
}

.report-filter-label.MuiTypography-root {
	font-weight: bold;
	font-size: 20px;
	color: #3d5564;
	margin-left: 1.1rem;
}

.report-filter.card {
	padding: 1rem 1.5rem;

	.report-filter-fields {
		padding: 1rem 0;
	}
}
