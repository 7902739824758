.step-plans {
    display: flex;
    justify-content: center;
    padding: 5px;
}

.plan-card {
    
    color: white;
    margin: 10px;
    border-radius: 10px;
    padding: 20px;
    width: 250px;
}

.plan-section {
    margin-bottom: 20px;
}

.plan-section h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.plan-section ul {
    list-style: none;
    padding: 0;
}

.plan-section ul li {
    margin-bottom: 5px;
}

button {
    background-color: #fff;
    color: #0a0a23;
    padding: 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
}

button:hover {
    background-color: #f0f0f0;
}

.combo {
    padding: 10px;
    border-radius: 5px;
}

.separete {
    width: 100%; 
    height: 1px;
    background-color: #ffffff;
    border: none;
}
.input-disabled {
  background-color: #f0f0f0; 
  pointer-events: none;
  color: #999;
}
.header {
    display: flex;
    text-align: center;
    justify-content: center;
}

.title {
    font-size: 2.5em;
    font-weight: bold;
    color: white;
}

.list {
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 15px;
    align-items: center;
}

.desc {
    margin: 0;
    padding: 0;
}

.step-container {
  width: 70%; /* Diminui a largura do container principal */
  margin: 20px auto; /* Centraliza o conteúdo */
}

.vehicle-table-container {
  margin-top: 20px;
  border: 1px solid #ddd; 
  border-radius: 5px;
  padding: 20px;
  background-color: #f9f9f9;
}

.vehicle-table {
  width: 50%;
  border-collapse: collapse;
}

.vehicle-table th,
.vehicle-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.vehicle-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.button-container {
  color: white;
  font-size: 20px;
}

.step-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  transition: color 0.3s ease;
}


.active {
  font-weight: bold;
}


.inactive {
  color: #999999; 
}

.step-label {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease; 
}


.step-icon {
  margin-right: 5px;
  font-size: inherit;
}


.table-container {
  max-height: 300px; 
  overflow-y: auto;  
  width: 50%;
  border: 1px solid #ddd;
}

.vehicle-table {
  width: 100%;
  border-collapse: collapse;
}

.vehicle-table th,
.vehicle-table td {
  padding: 8px 12px; 
  border: 1px solid #ddd; 
  text-align: left;
}


.table-container thead {
  position: sticky;
  top: 0;
  background-color: #f1f1f1; 
  z-index: 1;
}

.address-contact-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.contact-section, .address-section {
  flex: 1;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-item span {
  margin-right: 10px;
}

.MuiFormHelperText-root {
  font-size: 1.1rem;  // Aumenta o tamanho da fonte do erro
  color: red;
}

.address-form .MuiTextField-root {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .address-contact-container {
    flex-direction: column;
  }
}
