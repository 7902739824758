.color-picker-poppover {
	min-height: 200px;
	min-width: 200px;
	padding: 1rem;
	.vertical-table {
		display: flex;
		flex-direction: row;

		.color-bar {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}


	span.color {
		min-width: unset;
		min-height: unset;
		display: inline-block;
		margin: 3px;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			margin: 0;
			height: 30px !important;
			width: 30px !important;
		}
	}
}

.MuiFormControl-root.MuiTextField-root.color-picker-input {
	cursor: pointer;
}
