.graphCard {
    height: auto;

    .echarts-for-react {
        height: 70vh !important;
    }
}

.contentCardSpeeding {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 10px 0px 0px;

    .tabActiveTable {
        position: absolute;
        top: -70px;
        left: 20rem;
        width: 35%;
    }
}

.cardListSpeeding {
    .boxTitle {
        padding-bottom: 20px;
    }
}

.speeding-graph {
    width: 100%;
    max-width: -webkit-fill-available;
}

.vertical,
.verticalInverse {
    .card {
        &.cardListSpeeding {
            height: 100%;
        }

        &.speeding-graph {
            height: 100%;
        }
    }
}

.horizontal,
.horizontalInverse {
    .card.cardListSpeeding {
        height: 100%;
    }
}

.expandedCard {
    .graphCard {
        width: 100% !important;
        height: 100%;

        .echarts-for-react {
            height: 100% !important;
        }
    }
    .custom-table {
      max-height: unset;
    }
}
