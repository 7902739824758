.fleet-evolution-box {
	display: flex;
	color: #3d5564;

	.fleet-evolution-tab {
		flex: 1;
		position: relative;
		margin-right: 0.7rem;

		.percent-label {
			font-size: 18px;
			font-weight: bold;
			display: flex;
			justify-content: center;
		}
	}

	.fleet-evolution-chart {
		flex: 2;
		border-left: 1px solid rgba($color: #999, $alpha: 0.2);

		.fleet-evolution-title {
			padding: 0.8rem;
			.MuiTypography-root {
				font-size: 20px;
			}
		}
	}
}

.fleet-evolution-chart {
	display: flex;
	flex-direction: column;
	padding: 0.2rem 0;

	.title {
		.MuiTypography-root {
			color: #3d5564;
			font-weight: bold;
			font-size: 18px;
		}
		padding: 1rem 0;
		margin-left: 1rem;
		margin-right: 2rem;
		border-bottom: 1px solid rgba($color: #999, $alpha: 0.5);
	}

	.chart {
		flex: 1;
		display: flex;
		flex-direction: row;

		.x-axis {
			writing-mode: tb-rl;
			transform: rotate(-180deg);
			text-align: center;

			.MuiTypography-root {
				color: #3d5564;
				height: auto;
				margin: auto;
				padding: 1.5rem 0.5rem;
				margin: 2rem 0;
				font-size: 18px;
			}
			margin: 0 1rem;
		}

		.echart {
			flex: 1;
		}
	}
}
