.panel-edit-fleet {
    padding: 1rem 1.5rem;

    .top-button {
        .MuiButton-label {
            text-transform: none !important;
            font-weight: bold;
            font-size: 14px;
        }
    }
}

#edit-fleet-form.ceabs-register-form {
    .register-field {
        padding-left: 0;
    }
}
