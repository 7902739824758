.field-file {
	width: auto;
	height: 135px;

	border-radius: 3px;
	position: relative;
	background-color: rgba($color: #afc3d2, $alpha: 0.2);

	&.field-image-error {
		border: 1px solid rgb(212, 208, 208);
	}

	&.no-button {
		cursor: pointer;
	}

	&.no-button {
		cursor: pointer;
	}

	.field-cover {
		width: 100%;
		height: 100%;
		border-radius: 3px;
	}

	.field-placholder {
		padding: 0.5rem;
		display: flex;
		align-items: center;
		position: relative;

		justify-content: center;
		height: 100%;
		width: 100%;
		box-sizing: border-box;
		color: #afc3d2;

		img {
			width: 60%;
			margin: 0.5rem;
			height: 60%;
		}

		svg {
			width: auto;
			height: auto;
			margin: 0.5rem;
		}

		.text {
			display: block;
			font-weight: bold;
			font-size: 20px;
		}
	}

	label {
		position: absolute;
		bottom: -2px;
		right: -2px;

		.MuiButtonBase-root {
			width: 0.5rem;
			min-width: unset;
		}
	}

	.close-button {
		position: absolute;
		right: 0;
		top: 0;
		img {
			filter: contrast(200%) brightness(150%);
		}
	}
}

#register-radio span svg {
	height: 24px;
    width: 24px;
}