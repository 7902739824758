.msg-alert-points-import .MuiAlert-standardInfo {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

.msg-alert-points-import .MuiAlert-message {
	height: 40vh;
	overflow: auto;
	-webkit-user-modify: read-write-plaintext-only;
	font-size: initial;
}

.import-title-info {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px !important;
}

.import-title-info span {
	margin-right: 10px;
}

.card-import-info {
	background: #eff3f6 !important;
  padding: 0 20px;

  .boxTitle{
    background: none;
  }
}

.main-info span p {
	font-size: 14px;
	color: #3d5564;
	font-weight: bold;
}

.item-info-internal {
	display: flex;
	margin: 5px 0 5px 0px;
	align-items: center;
}

.item-info-internal span {
	background: #dce5eb;
	padding: 2px 5px;
	margin: 0px 5px 0 0px;
}

.item-info-internal span p {
	font-size: 12px;
	color: #3d5564;
	padding: 0 !important;
	display: flex;
	align-items: center;
}

#points-import-cancel-button {
	padding-left: 0;
}

#points-import-save-button {
	padding-right: 0;
}