.google-maps-card {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.control-box {
	background-color: rgb(255, 255, 255);
	border: 2px solid rgb(255, 255, 255);
	border-radius: 3px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
	cursor: pointer;
	margin: 10px;
	text-align: center;
	width: 40px;

	.MuiListItem-gutters {
		padding: 11px;
	}
}

.map-card {
	height: 100%;
}

.vertical, .verticalInverse {
	.map-card {
		height: 500px;
	}
}


.boxGoogleMaps {
	.site-layout {
		margin-top: 60px;
		margin-left: 40%;
	}

	.box-sider {
		position: fixed;
		z-index: 5;
		left: 0;
		top: 60px;
		height: calc(100vh - 60px);
		box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.3);
		overflow: auto;
		background: #fff;

		&::-webkit-scrollbar-track {
			display: none;
		}

		&::-webkit-scrollbar {
			width: 7px;
			background-color: #fff;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: #dcdcdc;
		}
	}

	.table-map {
		background: #fff;

		td,
		th {
			font: normal 12px/16px Calibri, sans-serif;
		}

		.ant-table-header {
			color: black;
			background-color: #eee;
		}

		.ant-table-thead th {
			background-color: #eee;
			color: #707070;
		}

		.iginition-span.on {
			color: #2dbe28;
			sup {
				background-color: #2dbe28;
			}
		}

		.iginition-span.off {
			color: #c72121;
			sup {
				background-color: #c72121;
			}
		}

		.address {
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.iginition-span {
			span {
				margin-right: 0.3rem;
				sup {
					font-weight: bold !important;
				}
			}
		}
	}

	.box-requisition {
		padding: 22px 14px 18px 18px;
		background: #fff;

		span.label {
			color: #aaaaaa;
			margin-bottom: 11px;

			strong {
				color: #707070;
			}
		}

		.btn-export {
			background-color: transparent;
			color: #457bb1;
			padding-left: 15px;

			&.ant-btn-loading i:not(.anticon-loading) {
				display: none;
			}
		}
	}

	footer {
		background: #fff;
	}
}

.info-view,
.marker-info-view {
	background-color: #fff;
	width: 350px;
	border-radius: 3px;

	.header {
		cursor: pointer;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;

		&.on {
			background-color: #71c154;
		}

		&.off {
			background-color: #cc4848;
		}

		padding: 0.5rem 1rem;
		color: #fff;
		position: relative;
		display: flex;
		align-items: center;

		.MuiTypography-root {
			font-size: 16px;
			line-height: 16px;
			font-weight: bold;
		}

		.icon {
			background-color: transparent;
			padding: 0.5rem;
			bottom: -30px;
			border-radius: 50%;
			position: absolute;
			right: -1.5rem;
		}
	}

	.body {
		padding: 0.8rem;

		.line {
			box-sizing: border-box;
			position: relative;
			display: flex;
			align-content: normal;

			> div {
				padding: 0.3rem 0;
				padding-left: 1rem;

				box-sizing: border-box;
				min-height: 13px;

				.MuiTypography-root {
					font-size: 13px;
				}
			}

			.label {
				background-color: rgba($color: #afc3d2, $alpha: 0.13);
				margin-left: 0.2rem;
				color: #afc3d2;
				text-align: right;
				padding-right: 0.7rem;
			}

			.border {
				position: absolute;
				bottom: 1px;
				right: 5%;
				display: block;
				width: 90%;
				height: 1px;

				border-bottom: 1px solid rgba($color: #afc3d2, $alpha: 0.33);
			}

			.value {
				color: #3d5564;
				img {
					width: 20px;
				}
			}
		}
	}
}

.info-view {
	.body .line {
		label {
			width: 30%;
		}

		.value {
			width: 70%;
		}
	}

	.line div.label {
		flex-grow: 1;
	}
}

.marker-info-view {
	.body .line {
		label {
			width: 50%;
		}

		.value {
			width: 50%;
		}
	}
}

.MuiPaper-root.control-box {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	border-radius: 2px;
	cursor: pointer;
	border: none;

	.MuiList-root {
		padding: 0;
	}
}

.gm-style {
	.gm-style-iw {
		// border: 1px solid blue;
		border-radius: 0 !important;
		padding: 0 !important;
		max-width: unset !important;
		max-height: unset !important;
		.gm-style-iw-d {
			max-height: unset !important;
			overflow: unset !important;
		}

		padding-top: 1rem !important;
		padding-right: 1rem !important;
		box-shadow: none !important;
		background-color: transparent !important;

		> button {
			display: none !important;
		}
	}

	.gm-style-iw-t::after {
		display: None !important;
	}
}
