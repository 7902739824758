.cardsAlertsLists {
	min-height: 50vh;
	display: flex;
	justify-content: stretch;
	align-items: stretch;

	.group-items .children {
		height: unset;
	}

	.card-alerts-list {
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 0;

		.itemTitle {
			width: auto !important;
		}

		.titleBtn {
			display: flex;
			width: 90%;
			align-items: center;
			justify-content: flex-end;

			.boxSearchComp {
				margin: 0 8px;
			}
		}

		.boxRangeAlerts {
			display: flex;
			justify-content: space-around;
		}

		.table-result {
			
			.MuiTableContainer-root {
				height: 32vh;
			}
			th {
				padding: 1px 5px;

				p {
					font-size: 12px !important;
					font-weight: bold;
				}
			}

			td {
				div {
					display: flex;
				}
				span {
					font-size: 12px !important;
					text-transform: capitalize;
				}
				p {
					text-align: start;
				}
			}
		}
	}

	.card-alerts-list-tabs {
		margin: 0;
		padding: 0;
		height: 100%;
	}
}
