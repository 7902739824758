.fuso-horario {
	width: 100%;
	height: 30px;
	background: rgba(175, 195, 210, 0.15);
	display: flex;
	justify-content: flex-end;
	align-items: center;
    padding-right: 10px;
	box-sizing: border-box;
	box-shadow: 0 0 5px 0 rgba(0,0,0,.15);

	.fuso {
		font-size: 10px;
	}
}
