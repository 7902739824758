.mapScreenVehicle{
  margin: 15px 0;

  .loadingGridData{
    height: 85%;
    width: 100%;
  }
}

.classMainTabDash{
  .MuiTabs-root{
    padding: 0 0 0 25px;
    border-bottom: none !important;
    box-shadow: 0px 1px 2px 0px #ccc;
  }
}

#TopTabDashboardVehicle {
  .card.expandedCard {
    
    margin-top: 0;

    .children {
      height: calc(100% - 1.75rem);
    }

    .dashboardVehicleMainMap {
      height: 100%;
      .map-card {
        height: 100% !important;
      }
    }
  }
}