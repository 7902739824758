#register-points-interest-container {
    height: calc(95vh - 10rem);

    .card.form-card {
        height: 100%;
    }

    >div.MuiGrid-container {
        height: 100%;
    }
}