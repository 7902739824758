.ceabs-layout {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-content: stretch;

	.MuiList-root {
		.MuiButtonBase-root {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			// padding: 25px 0;
		}
	}

	.ceabs-main {
		flex: 1;
		display: flex;
		flex-direction: row;

		.ceabs-menu {
			position: fixed;
			width: 90px;
			height: calc(100vh - 70px);
			left: 0;
			top: 70px;
			padding-top: 0;
			z-index: 1000;
		}

		.ceabs-content {
			flex: 1;

			.MuiContainer-root {
				padding: 0;
				//height: 100%;
			}
		}
	}

	.ceabs-header {
		position: fixed;
		width: 100%;
		height: 70px;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		background-color: #fff;
		color: #0f0f0f;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
		-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.33);
		z-index: 1000;

		.MuiToolbar-root {
			display: flex;
			justify-content: space-between;
			flex: 1;
			position: relative;

			.logo-container {
				flex: 0.15;

				.logo {
					height: 30px;
				}
			}

			.icon-menu-list {
				margin: 0px 30px 0px 0px;
				cursor: pointer;
				z-index: 3;
			}

			.icon-menu-logo {
				display: flex;
				flex: 5;
				margin: auto;
				justify-content: center;
				align-items: center;
				position: absolute;
				height: 100%;
				width: 100%;
				right: 0;
				z-index: 0;


				img {
					height: 7.0rem;
				}
			}

			.user-profile {
				display: flex;
				z-index: 3;

				> div {
					text-align: right;
					display: flex;
					align-items: center;
					margin: 0.3rem;
				}

				.notification {
					margin-right: 1rem;
					margin-left: 1rem;
				}

				.action-info {
					margin: 0;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.user-avatar {
					width: 35px;
					height: 35px;
				}
			}

			.header-title {
				padding-left: 3rem;

				font-size: 20px;
				line-height: 27x;
				font-weight: lighter;

				// text-transform: uppercase;
			}

			.username {
				font-size: 14px;
				color: #3d5564;
			}

			.logout {
				float: right;
			}
		}
	}
}
