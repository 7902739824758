.box-group {

	margin-left: 1rem;
	margin-bottom: 0px;
    display: flex;
	flex-direction: column;

    .table-result td div {
        text-align: start;
    }

    .box-group-card {
        display: flex;
		margin-top: 2rem;

        .boxTitle {
            background: none;
        }

        .box-card-group-item {
            display: flex;
            margin-top: 0;
            width: 45vw;

            .group-items {
                margin: 0;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);

                .MuiGrid-item {
                    width: 50%;
                }

                .children {
                    height: 50vh;
                    padding-right: 5px;
                }

                .table-result {
                    height: 40vh;
                    overflow: auto;
                    .MuiTableContainer-root {
                        height: 40vh;
                    }
                }

                .table-item-value {
                    display: flex;
                    padding: 0;
                    margin: 0;
                }

                table {
                    overflow: none;
                }

                .search-group-item {
                    position: absolute;
                    top: 9px;
                    left: 20.5vw;
                    width: 51%;
                    display: flex;
                }
            }
        }
    }
}

.loadingGroupCard {
    width: 100%;
    height: 100%;
}

.tied-item {
    margin: 0;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

button.btn-add-group-items {
    width: 25vw;
    min-width: fit-content;
    white-space: nowrap;
    padding: 0px 16px;
}

.btn-add-group-items p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold !important;
    color: white;
    text-transform: none;
    font-size: 20px;
    padding: 5px 0;
}

.btn-add-group-items img {
    width: 20px;
    margin: 10px;
}

.btn-add-group-items-smaller p {
    padding: 6px 0 !important;
}

.btn-edit-linked {
    margin-left: 5px !important;
    padding: 0 30px !important;
    height: auto;
}

.btn-edit-linked p {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: space-around;
    color: white;
    font-size: 14px;
    svg {
        margin-right: 5px;
    }
}

.card-group-header {
    display: flex;

    .card-group-search {
        // width: 170px;
        width: 13rem;
        margin: 0 12px;
    }
}
