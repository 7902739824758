.speeding-report-scheduling, .speeding-report-sent {
    th, td.MuiTableCell-root {
        padding-left: 1rem !important;
        padding-right: 0.5rem;
       >p, >div {
           display: flex;
       } 
    }

    .speeding-email {
        width: 55%;
    }
}