.top-ten-table {
	max-height: 400px;
	min-height: 200px;
	padding-right: 1rem;
	box-sizing: border-box;

	thead {
		th {
			font-size: 20px;
			color: #3d5564;
			font-weight: bold;
		}
	}

	tbody {
		th {
			font-size: 18px;
			color: #3d5564;
		}
	}

	th.borded {
		border-left: 1px solid rgba(224, 224, 224, 0.5);
	}

	.numerate-column .number {
		line-height: 22px;
		font-size: 28px;
		color: #afc3d2;
		font-weight: bold;
	}
}
