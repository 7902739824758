.vehicle-icon {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 60%;
		height: 60%;
	}

	margin: 0.5rem;
}
