.ceabs-layout {
	.MuiButton-root.button-popover {
		padding: 0.8rem 1rem;
		border-radius: 3px;
		box-shadow: none;

		box-sizing: border-box;
		font-size: 12px;
		line-height: 15px;

		&.on {
			border: 1px solid #afc3d2;
			background: #eef2f5;
			color: #3d5564;
		}

		&.off {
			border: none;
			color: #fff;
			background: #afc3d2;
		}

		.MuiButton-label {
			justify-content: flex-start;
			text-align: left;
		}

		svg {
			width: 25px;
			height: 25px;
			stroke-width: 10px;
			overflow: visible;
			margin-right: 0.7rem;
			stroke-width: 8px;
			font-size: 20px;
		}
	}
}

.box-autocomplete {
	padding: 0.8rem;
	min-height: 50px;
	width: 220px;
	border-radius: 3px;
	background-color: #fff;
	box-shadow: 0px 2px 10px #00000027;
	z-index: 9000 !important;
	font-size: 14px;

	.container-arrow {
		display: block;
		position: absolute;
		top: -24px;
		left: 2.2rem;

		width: 0;
		height: 0;

		border: 0 solid #fff;
		border-width: 12px;
		border-top-color: transparent;
		border-left-color: transparent;
		border-right-color: transparent;
	}

	.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
		z-index: 9000 !important;

		.MuiInputBase-root.MuiOutlinedInput-root {
			background-color: #eef2f5;
			border-radius: 3px;
			border: none;
			padding-right: 0.5rem;
			font-size: 14px;

			.MuiCircularProgress-root {
				margin-right: 0.5rem;
			}
		}

		fieldset {
			display: none;
		}
	}
}

.autocomplete-option {
	width: 100%;
	border-bottom: 1px solid rgba($color: #999, $alpha: 0.3);
	font-size: 10px;
	align-items: "flex-start";
	padding: 0;
	font-size: 14px;

	svg {
		width: 18px;
		height: 18px;
	}
}

.popper-autocomplete {
	z-index: 3200;
}
