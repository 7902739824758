.card-news {
  padding: 30px;
  height: 80%;
  max-height: 430px;

  .flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    border-bottom: 1px solid #afc3d2;
  }

  p b {
    color: #3D5564;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .text-news {
    margin-left: 40px;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text-news-2 {
    margin-left: 20px;
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px !important;
  }


  .img-div-news {
    margin: auto 0;
  }

  .img-news {
    width: 550px;
    height: auto;
  }

  .img-news-2 {
    width: 450px;
    height: auto;
  }

  .img-news-3 {
    width: 350px;
    height: auto;
  }

  .img-news-4 {
    width: 400px;
    height: auto;
  }

  .img-news-5 {
    width: 250px;
    height: auto;
  }
}

.card-news-2 {
  position: relative;
  padding: 30px;
  height: 100%;
  max-height: 630px;

  .flex {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    border-bottom: 1px solid #afc3d2;
  }

  p b {
    color: #3D5564;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .img-news-4 {
    max-Width: 100%;
    width: 100%;
    height: auto;
  }
}

.dots-container {
  z-index: 99999;
  position: absolute;
  bottom: 10px;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: row;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: black;
}

.prev-button, .next-button {
  cursor: pointer;
  position: absolute;
  z-index: 99999;
  top: 44%;
  background-color: rgba(0, 0, 13, 0.1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-button {
  right: 40px;
}

.prev-button {
  left: 40px;
}

.prev-button:hover, .next-button:hover {
  background-color: rgba(0, 0, 13, 0.6);
}
