.ceabs-layout .ceabs-register-form .profile-panel {
	padding-top: 1rem;

	.rules-box {
		color: #afc3d2;
		font-size: 0.7em;
		font-style: italic;

		&.rules-password {
			column-count: 2;
		}

		&.rules-logo {
			padding-top: 0.5rem;
			font-size: 12px;

			.rules-title {
				font-weight: bold;
			}
		}
	}

	.remove-logo {
		background-color: #afc3d2;
		color: #fff;
		font-weight: bold;
		font-size: 12px;
		max-height: 40px;
	}

	.configuration-title {
		color: #3d5564;
		padding: 1rem 0;
		width: 100%;
		font-size: 20px;
		font-weight: bold;
		border-bottom: 1px solid rgba($color: #afc3d2, $alpha: 0.6);
	}

	.register-field.register-switch {
		.MuiFormControlLabel-root {
			justify-content: flex-end;
			.MuiFormControlLabel-label.MuiTypography-root {
				font-size: 14px;
				text-align: left;
			}
		}
	}
}

.ceabs-layout .card.form-card.profile-form-card {
	padding: 2.5rem;

	.profile-no-padding-left {
		&>div {
			padding-left: 0;
		}
	}
	.profile-no-padding-right {
		&>div {
			padding-right: 0;
		}
	}
	
	.profile-report-switch label{
		margin-left: 0;
	}

	button.MuiTab-root {
		padding-left: 0;
	}
}